<template>
  <div class="remark-exam-list-layout">
    <Breadcrumb>
      <BreadcrumbItem>考试管理</BreadcrumbItem>
      <BreadcrumbItem>批阅</BreadcrumbItem>
      <BreadcrumbItem>待阅卷考试列表</BreadcrumbItem>
    </Breadcrumb>
    <div class="wrapper">
      <Table :columns="columns" :data="examList">
        <template slot-scope="{ row }" slot="actions">
          <JYIcon class="action-icon" href="#icon-sousuo1" @click="$router.push(`/manage/exam/read/${row.examId}`)" />
        </template>
        <template slot-scope="{ row }" slot="startTime">
          <div>{{ $formatTime(row.startTime, 'yyyy-MM-dd hh:mm:ss') }}</div>
        </template>
        <template slot-scope="{ row }" slot="endTime">
          <div v-if="row.endTime">
            {{ $formatTime(row.endTime, 'yyyy-MM-dd hh:mm:ss') }}
          </div>
          <div v-else>未设置</div>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import JYIcon from '@components/common/JYIcon'
import ExamAPI from '@api/exam'
export default {
  name: 'RemarkExamList',
  components: {
    JYIcon
  },
  created() {
    this.requestData()
  },
  data() {
    return {
      columns: [
        {
          key: 'examName',
          title: '考试名称'
        },
        {
          key: 'unMarkQuestionCounts',
          title: '待阅题数'
        },
        {
          key: 'allMarkQuestionCounts',
          title: '总题数'
        },
        {
          key: 'startTime',
          slot: 'startTime',
          title: '开始时间'
        },
        {
          key: 'endTime',
          slot: 'endTime',
          title: '结束时间'
        },
        {
          key: 'actions',
          title: '操作',
          slot: 'actions',
          align: 'center',
          width: 100
        }
      ],
      examList: []
    }
  },
  methods: {
    async requestData() {
      const userId = this.$store.getters['user/info'].userId
      const { res } = await ExamAPI.getRemarkExamList(userId)
      this.examList = res
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/mixin';
.remark-exam-list-layout {
  text-align: left;
  padding: 20px;
}
.wrapper {
  .--mixin-shadow;
  background: white;
}
.action-icon {
  cursor: pointer;
}
</style>
